// documentation: https://partner.cashpresso.com/#/api#posCalculator

const startCashpresso = (productAmount, apiKey) => {
  // eslint-disable-next-line no-undef
  $(document).ready(() => {
    const amount = productAmount ?? 0;
    // eslint-disable-next-line no-undef
    CreditCalculator.init(
      {
        targetSelector: '#financing',
        language: 'de',
        amount,
        // verified to BK contract with Cashpresso
        // automatically adds 2*30 free days (coming from Cashpresso)
        // currently disabled as there are no promotions
        interestFreeDays: 305, // Promo until 2024/07/22
        partnerApiKey: apiKey,
        hideButton: true,
        format: 'wide',
        mode: 'live',
      },
    );
  });
};

export default startCashpresso;
