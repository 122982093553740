







































import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfBar, SfModal } from '@storefront-ui/vue';
import { useUiState } from '~/composables/useUiState';
import { Routes } from '~/helpers/routes';
import startCashpresso from './cashpresso';

export default defineComponent({
  name: 'FinancingModal',
  components: {
    SfModal,
    SfBar,
  },
  setup() {
    const { isFinancingModalOpen, financingMetadata } = useUiState();
    const { $config } = useContext();
    const { cashpressoApiKey } = $config;

    return {
      isFinancingModalOpen,
      financingMetadata,
      cashpressoApiKey,
      Routes,
    };
  },
  mounted() {
    startCashpresso(this.financingMetadata?.price as number || 0, this.cashpressoApiKey);
  },
});
